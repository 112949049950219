<template>
  <el-card class="box-card">
  <div class="depart-box">
    <!--左侧树形-->
    <div class="left-tree">
      <el-tree :data="treedata"
               accordion
               default-expand-all
               empty-text="暂无数据"
               :props="defaultProps"
               @node-click="handleNodeClick"></el-tree>
    </div>

    <div class="right-table">
      <div>
        <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
          <el-form-item label="部门名称">
            <el-input style="width: 230px" placeholder="请输入部门名称" clearable v-model="queryParams.searchName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchList">查询</el-button>
          </el-form-item>
          <el-button style="float: right" type="primary" @click="departAddorEdit">新增部门</el-button>
        </el-form>
      </div>
      <el-table v-loading="loading" style="width: 100%;margin-top: 10px" :data="tableData" border :header-cell-style="headerCellStyle">
        <template slot="empty">
          <IsEmpty />
        </template>
        <el-table-column type="index" label="序号" width="50" align="center" :index="count" />
        <el-table-column prop="departmentName" label="部门名称" align="center"  />
        <el-table-column prop="departmentPhone" label="部门电话"  align="center"  />
        <el-table-column prop="parentName" label="上级部门"  align="center"  />
        <el-table-column prop="departmentAddress" label="部门地址"  align="center"  />
        <el-table-column prop="address6" label="操作"  width="230" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
            <el-button type="text" @click="departAddorEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="delItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <departAddorEditDilog @reloadPage="reloadPage" :treedata="treedata" :departItem="departItem" :departAddorEditVisible.sync="departAddorEditVisible"/>
    <departInfoDetailDilog :detailData="detailData" :departInfoDetailVisible.sync="departInfoDetailVisible"/>
  </div>
  </el-card>
</template>

<script>
  import { departmentTreeList, departmentList, departTreeSearch, deleteDepart } from '../../../api/departManage'
  import departAddorEditDilog from "./components/departAddorEditDilog";
  import departInfoDetailDilog from "./components/departInfoDetailDilog";
  import { getContent } from '@/utils/index'
  import { SIGNOPTIONS } from '@/data/dataDictionary'
  export default {
    name: "unitApproved",
    components: {
      departAddorEditDilog,
      departInfoDetailDilog
    },
    data() {
      return {
        SIGNOPTIONS,
        flag: false, //是否是点击树查询
        treeClickData: {}, //点击树的参数
        detailData: {}, //详情
        departItem: {}, //选择的部门数据
        departAddorEditVisible: false, //新增或者编辑弹框
        departInfoDetailVisible: false, //查看详情
        loading: false,
        total: 0,
        tableData: [],
        queryParams: { //查询参数
          pageNum: 1,
          pageSize: 10,
          searchName: '' //查询参数
        },
        treeQueryParams: {
          pageNum: 1,
          pageSize: 10,
        },
        treedata: [],
        defaultProps: {
          id: 'id'
        }
      }
    },
    mounted() {
      this.departmentTreeData()
      this.getList()
    },
    methods: {
      getContent,
      reloadPage() { //重新加载页面
        this.departmentTreeData()
        this.getList()
      },
      //分页序号
      count(index) {
        if(this.flag) {
          return (this.treeQueryParams.pageNum - 1) * this.treeQueryParams.pageSize + index + 1
        }else {
          return (this.queryParams.pageNum - 1) * this.queryParams.pageSize + index + 1
        }

      },
      /*查询参数*/
      searchFromData(data) {
        this.queryParams = {...this.queryParams, ...data}
      },
      handleSizeChange(pageSize) {
        this.queryParams.pageSize = pageSize
        this.queryParams.pageNum = 1
        //树查询参数
        this.treeQueryParams.pageNum =1
        this.treeQueryParams.pageSize = pageSize
        if(this.flag) this.getTreeNodeList()
        else  this.getList()

      },
      handleCurrentChange(pageNum) {
        this.queryParams.pageNum = pageNum

        this.treeQueryParams.pageNum = pageNum
        if(this.flag) this.getTreeNodeList()
        else  this.getList()

      },
      //查询按钮
      searchList() {
        this.flag = false
        this.queryParams.pageNum = 1
        this.treeQueryParams.pageNum =1
        this.getList()
      },
      //  查看详情
      toDetail(item) {
        this.detailData = item
        this.departInfoDetailVisible = true
      },
      //  新增或者编辑弹框
      departAddorEdit(item) {
        if(item && item.id) {
          this.departItem = item //编辑
        }else {
          this.departItem = { //新增
              departmentName: '', //部门名称
              departmentFullName: '', //部门全称
              departmentPhone: '', //部门电话
              parentId: '', //上级部门
              departmentSort: 1, //部门排序
              departmentAddress: '', //部门地址
              description: '', //部门描述
              treeParams: [], //上级部门参数 要删除
              id: -1 //部门id
          }
        }
        this.departAddorEditVisible = true
      },
      //删除
      delItem(item) {
        this.$confirm(`此操作将删除"${item.departmentName}", 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteDepart({id: item.id}).then(res => {
            if(res.state === 0) {
              this.$message.success(res.data)
              if(this.flag) this.getTreeNodeList()
              else  this.getList()
              this.departmentTreeData()
            }
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //树选择-查询
       handleNodeClick(data) {
        this.flag = true
        this.treeClickData = data
         this.treeQueryParams.pageNum = 1
         this.queryParams.pageNum = 1
         this.getTreeNodeList()
      },
      //点击树查询列表
      async getTreeNodeList() {
        this.loading = true
      let  params = this.treeClickData
        params.pageNum = this.treeQueryParams.pageNum
        params.pageSize = this.treeQueryParams.pageSize
        const res = await departTreeSearch(params)
        if(res.state === 0) {
          this.loading = false
          this.tableData = res.data.rows
          this.total = res.data.total
        }else {
          this.loading = false
        }
      },
    //  获取treeData
      async departmentTreeData() {
        const res = await departmentTreeList()

        if(res.state === 0 && res.data[0] !== null) {
          this.treedata = res.data
        }else {
          this.treedata = []
        }
      },
    //  获取列表数据
      async getList() {
        this.loading = true
        let params = JSON.parse(JSON.stringify(this.queryParams))
        Object.keys(params).map((item) => { //清空值为空的参数
          if(['null', ''].includes(params[item])) {
            Reflect.deleteProperty(params, item)
          }
        })
        try {
          const res = await departmentList(params)
          this.loading = false
          this.tableData = res.data.rows
          this.total = res.data.total
        }catch (e) {
          console.log(e)
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .box-card {
    min-height: calc(100% - 10px);
  }
  .depart-box {
    background-color: #FFFFFF;
    height: calc(100% - 10px);
    .left-tree {
      padding: 30px 0 0 26px;
      width: 270px;
      box-sizing: border-box;
      display: inline-block;
      float: left;
    }
    .right-table {
      display: inline-block;
      box-sizing: border-box;
      padding: 30px 40px 40px 40px;
      width: calc(100% - 270px);
      position: relative;
    }
    .right-table:after {
      content: '';
      height: 100vh;
      border-left: 1px solid #EEEEEE;
      position: absolute;
      top: -20px;
      left: 0;
    }
  }

</style>
