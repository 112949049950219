<template>
    <el-dialog
        :title="departItem.id ? '部门编辑' : '部门新增'"
        :visible.sync="departAddorEditVisible"
        :close-on-click-modal="false"
        width="900px"
        append-to-body
        :before-close="handleClose"
    >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门名称" prop="departmentName">
                        <el-input class="w230" clearable v-model="ruleForm.departmentName" placeholder="请输入部门名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="部门全称" prop="departmentFullName">
                        <el-input class="w230" clearable v-model="ruleForm.departmentFullName" placeholder="请输入部门全称"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门电话" prop="departmentPhone">
                        <el-input class="w230" clearable v-model="ruleForm.departmentPhone" placeholder="请输入部门电话"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="上级部门" prop="treeParams">
                        <el-cascader class="w230" :options="treedata" :props="defaultProps" ref="cascaderAddr" v-model="ruleForm.treeParams" :show-all-levels="false" clearable>
                        </el-cascader>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门排序" prop="departmentSort">
                        <el-input-number class="w230" v-model="ruleForm.departmentSort" :min="1" :max="9999" label="描述文字"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="部门地址" prop="departmentAddress">
                        <el-input class="w230" clearable v-model="ruleForm.departmentAddress" placeholder="请输入部门地址"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="是否为志愿者部门" prop="weatherVolunteerDepartment">
                        <el-select v-model="ruleForm.weatherVolunteerDepartment" placeholder="请选择">
                            <el-option label="不是" :value="0"></el-option>
                            <el-option label="是" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-form-item label="部门简介" prop="description">
                        <el-input :rows="4" clearable type="textarea" placeholder="请输入部门简介" v-model="ruleForm.description"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { departAddOrEdit } from '../../../../api/departManage'
export default {
    name: 'departAddorEditDilog',
    props: {
        departAddorEditVisible: {
            type: Boolean,
            default: false
        },
        departItem: {
            type: Object,
            default: () => {
                return {}
            }
        },
        treedata: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        var checkPhone = (rule, value, callback) => {
            let regPone = null
            let mobile = /^((13|14|15|17|18)[0-9]{1}\d{8})$/ // 最新16手机正则
            let tel = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/ // 座机
            if (value !== null && value.charAt(0) === '0') {
                // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
                regPone = tel
            } else if (value !== null && value.charAt(0) !== '0') {
                regPone = mobile
            }
            if (regPone === null) {
                return callback(new Error('请输入电话'))
            } else if (!regPone.test(value)) {
                return callback(new Error("请输入正确的电话格式,其中座机格式'区号-座机号码'"))
            } else {
                callback()
            }
        }
        return {
            ruleForm: {
                departmentName: '', //部门名称
                departmentFullName: '', //部门全称
                departmentPhone: '', //部门电话
                parentId: '', //上级部门
                departmentSort: 1, //部门排序
                departmentAddress: '', //部门地址
                description: '', //部门描述
                treeParams: [], //上级部门参数 要删除
                id: -1, //部门id
                weatherVolunteerDepartment: 0 // 是否为志愿者部门0 不是 1是
            },
            rules: {
                departmentName: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
                departmentFullName: [{ required: true, message: '请输入部门全称', trigger: 'blur' }],
                departmentPhone: [{ required: true, validator: checkPhone, trigger: 'blur' }],
                treeParams: [{ required: true, message: '请选择上级部门', trigger: ['blur', 'change'] }],
                departmentSort: [{ required: true, message: '请输入部门排序', trigger: 'blur' }],
                departmentAddress: [{ required: true, message: '请输入部门地址', trigger: 'blur' }],
                weatherVolunteerDepartment: [{ required: true, message: '请选择', trigger: 'change' }]
            },
            defaultProps: {
                value: 'id',
                label: 'label',
                checkStrictly: true
            }
        }
    },
    watch: {
        departItem: {
            deep: true,
            handler(val) {
                console.log(val, '>>>>>>>>>>>>>')
                this.ruleForm = JSON.parse(JSON.stringify(val))
                let pid = val.parentId
                this.ruleForm.treeParams = this.getparentId(this.treedata, pid)
            }
        }
    },
    mounted() {
        setInterval(function () {
            // 解决级联选择器不点击圆点选中元素问题
            document.querySelectorAll('.el-cascader-node__label').forEach(el => {
                el.onclick = function () {
                    if (this.previousElementSibling) this.previousElementSibling.click()
                }
            })
        }, 1000)
    },
    methods: {
        //递归查询父级ID
        getparentId(treeData, parentId) {
            const find = (classifyList, id) => {
                let temp = [id]
                let forFn = function (arr, id) {
                    for (let i = 0; i < arr.length; i++) {
                        let item = arr[i]
                        if (item.children) {
                            let data1 = item.children.find(item1 => item1.id === id) //当前这项是否相等
                            if (data1) {
                                temp.unshift(item.id) //存在 push 并结束
                                forFn(classifyList, item.id)
                                break
                            } else {
                                forFn(item.children, id) //不存在递归查询
                            }
                        }
                    }
                }
                forFn(classifyList, id)
                return temp
            }
            return find(treeData, parentId)
        },
        handleClose() {
            this.resetForm('ruleForm')
            this.$emit('update:departAddorEditVisible', false)
        },
        //提交
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let params = JSON.parse(JSON.stringify(this.ruleForm))
                    params.parentId = params.treeParams[params.treeParams.length - 1]
                    Reflect.deleteProperty(params, 'treeParams')
                    this.departAddOrEditPost(params)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //  编辑、新增
        async departAddOrEditPost(params) {
            const res = await departAddOrEdit(params)
            this.$message.success(res.data)
            //请求成功，刷新页面
            this.$emit('reloadPage')
            this.$emit('update:departAddorEditVisible', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.w230 {
    width: 230px;
}
.info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
}
</style>
